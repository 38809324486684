.format {
    border-bottom: 1px #e4e3db solid;
    color: #31312f;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 0 8px;

    & span {
        font-size: 0.75rem;
    }
}

.formatIcon {
    align-self: center;
    margin-right: 4px;
    width: 30px;
    height: 30px;
}

.primaryFormat {
    margin-right: 4px;
    white-space: nowrap;
    font-weight: bold;
}
