.wrapper {
    display: flex;
    border-top: 1px solid #ddd;
}

.left {
    border-right: 1px solid #ddd;
}

.left,
.right {
    flex-shrink: 0;
    min-width: 50%;
    box-sizing: border-box;

    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 0.35rem;
}
