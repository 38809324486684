.ratings {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    gap: 4px;

    text-align: center;
}

.addPadding {
    padding: 1em 0 0.6em;
}
