.notSafeForWorkImageContainer {
    min-height: 134px;
    width: 134px;
    padding: 0.25rem;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: amped(colors.grey100);
    background-image: url('./sensitive-img.svg');
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-wrap: wrap;
    text-align: center;

    &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        border-radius: 4px;
    }
}
