.report {
    border-top: 1px solid #ddd;
    margin: 1em 0;
    padding-top: 0.5em;
    padding-left: 5px;

    &,
    & a {
        font-size: 14px;
        font-weight: normal;
        color: #2653d9;
    }
}
