/* stylelint-disable no-descending-specificity */
.card {
    position: relative;
    border: 1px #e4e3db solid;
    border-radius: 8px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.08), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
    letter-spacing: 0.01em;
    width: 150px;
    overflow: hidden;
    margin: 0;

    .wantlistButton {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: absolute;
        /* fallback image has a z-index of 10 */
        z-index: 11;
        span {
            display: flex;
            height: 24px;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 2px;
            border-radius: 0 0 2px 2px;
            border: 1px solid var(--border-light-border-03, #e4e3db);
            background: var(--background-light-background-03, #f5f4ec);
            box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.08);
        }

        button {
            all: inherit;
            display: flex;
            height: 24px;
            padding: 0 6px;
            align-items: center;
            gap: 4px;
            border-radius: 2px 6px 2px 2px;
            background: var(--background-light-background-03, #f5f4ec);
            cursor: pointer;
        }

        .inWantlist {
            background: #bf3a38;

            svg {
                color: #f5f4ec;
            }
        }
    }

    a {
        color: #20201f;
        display: inline-block;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;

        &:hover,
        &:focus {
            color: #20201f;
        }
    }

    .titleAndArtist {
        margin-bottom: 12px;
        padding: 0 8px;
        font-size: 0.875rem;
    }

    .thumbnailContainer {
        padding: 8px;
        position: relative;

        .skittleContainer {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 50;
        }
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 18px;

        &:focus {
            color: white;
        }
    }
}

.responsiveCard {
    @media (max-width: 587px) {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;

        img {
            margin: 0 auto;
            width: 100%;
            height: auto;
        }
    }
}

.released {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 126px;
    color: #6c6b68;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    font-size: 0.775rem;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    span {
        margin-bottom: 4px;
    }

    .dateAndCountry {
        color: #31312f;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 4px;
    }
}

.ctaButtonContainer {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ctaButton {
    box-sizing: border-box;
    /* to override amped */
    display: flex !important;
    justify-content: center;
    /* to override amped */
    height: 38px !important;
}

.thumbnail {
    object-fit: cover;
    aspect-ratio: 1;
}
