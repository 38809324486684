.simple {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
