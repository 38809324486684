.list {
    font-size: 0.875rem;
    list-style-type: none;
    margin-top: 0;
    padding: 0;

    li {
        margin-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.entity {
    color: #333;
}
