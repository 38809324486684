.wrapper {
    margin-bottom: 1em;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px;

    margin-top: -0.5em;
    margin-left: -0.5em;
}

.button {
    flex-shrink: 1;
    flex-grow: 1;
    white-space: nowrap;
    min-width: calc(50% - 0.5em);
    width: auto;
    display: flex;
    justify-content: space-evenly;

    margin-top: 0.5em;
    margin-left: 0.5em;

    svg {
        margin-right: 0.5rem;
    }
}
