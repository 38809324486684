.list {
    font-size: 0.875rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 18px;

    li {
        margin-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.role {
    color: #444;
}
