$collapse: 600px;

.header {
    font-size: 14px;
    border-bottom: none;

    svg {
        width: 14px;
    }
}

.versions {
    hyphens: auto;

    tr {
        border-bottom: 1px solid #eee;

        @media (max-width: $collapse) {
            position: relative;
            display: block;
            padding: 5px;
            padding-left: 15px;
            border-top: none;

            /* stylelint-disable-next-line a11y/no-display-none */
            &:first-of-type {
                display: none;
            }
        }
    }

    th,
    td {
        padding: 1em 0.25em;
        text-align: left;
        vertical-align: top;

        @media (max-width: $collapse) {
            display: block;
            padding: 0;
        }
    }

    th {
        vertical-align: bottom;
    }

    .title {
        min-width: 7em;
    }

    .label {
        min-width: 8em;
    }

    .cat,
    .country {
        min-width: 4em;
    }

    .year {
        min-width: 3em;
    }

    .skittles {
        white-space: nowrap;
    }
}

.quality {
    width: 10px;
    box-sizing: border-box;
    background: none;
    padding: 0;
    position: relative;
    cursor: pointer;

    &.needschanges {
        background: red;
    }

    &.newsubmission {
        background: #f2b90d;
    }

    &.reviewchanges {
        background: var(--grey30);
    }

    th& {
        height: auto;
    }

    .tooltip {
        visibility: hidden;
        position: absolute;
        /* Adjust "middle" for 5px tooltip tail */
        top: calc(50% - 5px);
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        pointer-events: none;
    }

    .label {
        background: black;
        color: white;
        font-size: 0.85em;
        white-space: nowrap;
        padding: 0 0.5em;
        position: relative;
    }

    .label::after {
        content: '';
        border: 5px solid transparent;
        border-top-color: black;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        cursor: pointer;
    }

    &:focus,
    &:hover {
        .tooltip {
            visibility: visible;
        }
    }

    &.none:focus,
    &.none:hover {
        .tooltip {
            visibility: hidden;
        }
    }

    @media (max-width: $collapse) {
        display: block;
        margin-right: 0.3em;
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 0;
        width: 5px;
        height: auto;
    }
}

.all {
    margin-left: 1em;
    margin-top: 0.2em;
    font-size: 0.85em;
    flex-shrink: 0;
    font-weight: normal;
}

.text {
    font-style: italic;
}

.headingContainer {
    display: flex;
    align-items: center;
}

.head {
    width: 100%;
    flex-grow: 1;
}

.unknown {
    white-space: nowrap;
}
