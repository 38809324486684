.items {
    display: flex;
    flex-wrap: wrap;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        vertical-align: top;
        display: inline-table;

        flex-shrink: 0;
        flex-grow: 1;
        min-width: 50%;
    }

    li {
        display: table-row;
    }

    .name {
        font-weight: normal;
        flex-shrink: 0;

        padding: 0;
        margin: 0;
        display: table-cell;
        width: 50%;
        padding-bottom: 0.25em;

        /* stylelint-disable-next-line a11y/content-property-no-static-value */
        &::after {
            /* non-breakable space */
            content: '\00a0';
        }
    }

    .name + * {
        display: table-cell;
    }
}
