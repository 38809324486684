.box {
    border: 1px solid black;
    padding: 5px;
    font-size: 0.92em;
    margin-top: 0.5em;

    .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding: 3px 0;
        display: flex;
        align-items: baseline;
    }

    h3 {
        font-size: inherit;
        display: inline-block;
        padding: 0;
        margin: 0;
        margin-right: 0.5rem;
    }

    .spacer {
        flex-grow: 1;
        min-width: 0.5rem;
    }

    .added {
        font-size: 0.857em;
    }
}

.remove {
    cursor: pointer;
    font-size: 0.8em;

    @media (max-width: 850px) {
        float: none;
        font-size: 1em;
    }
}

$break-field: 950px;

.field {
    margin-top: 0.3em;
    min-height: 1.6em;

    svg {
        height: 1em;
        float: right;
    }

    @media (min-width: $break-field) {
        display: flex;
        flex-wrap: wrap;
    }
}

.label {
    padding-left: 0.1em;
    font-weight: bold;

    @media (min-width: $break-field) {
        box-sizing: border-box;
        width: calc(40% - 0.5em);
        margin-right: 0.5em;
        flex-shrink: 1;
    }
}

.fieldIcon {
    color: var(--grey70);
    opacity: 0;
    position: absolute;
    right: 0.5em;
    top: 0.2em;
}

.loading {
    position: absolute;
    right: 0.5em;
    top: 0.2em;
    color: #aaa;
}

.wrapper {
    position: relative;
    min-height: 1.3em;
    padding: 0;
    margin: 0;
    display: block;
    border: none;
    background: none;
    font: inherit;
    width: 100%;
    text-align: left;

    &:focus,
    &:focus-within,
    &:hover {
        background: #fcf5c0;
        outline: -webkit-focus-ring-color auto 1px;

        .fieldIcon {
            opacity: 1;
        }
    }

    @media (min-width: $break-field) {
        width: 60%;
    }
}

.dvalue,
.edit {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0.15em 0;
    pointer-events: none;
}

.edit {
    font-weight: normal;
    color: var(--grey70);
    display: inline-block;
    z-index: 100;
}

.dropdown {
    display: inline-block;
    appearance: none;
    border: none;
    background: none;
    font: inherit;
    position: relative;
    width: 100%;
    outline: none;
    cursor: pointer;
    z-index: 100;
    opacity: 0;
}

.textedit {
    margin: 0.5em 0;
    width: 218px;
}

.textarea {
    width: 100%;
    box-sizing: border-box;
    font: inherit;
    resize: none;
    margin-bottom: 0.25em;
    padding: 0.25rem;
}

.save {
    margin-right: 0.25em;
}

.value {
    white-space: pre-line;
}
