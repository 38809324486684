.tracklist {
    width: 100%;
}

.heading {
    background: #e9e9e9;
    font-weight: bold;
}

.trackPos {
    padding-right: 14px;
    white-space: nowrap;
    width: 1%;
}

.subtrack {
    background: #f9f9f9;
}

.subtrackPos {
    position: relative;
    padding-left: 1.3rem;
    padding-right: 1em;
    white-space: nowrap;
    width: 1%;
}

.dash {
    float: right;
    margin-right: -11px;
}

.artist {
    text-align: right;
    padding-right: 14px;
    padding-left: 0;

    width: 33%;

    span {
        text-align: left;
    }

    &:empty {
        width: 0;
    }
}

.subtrackIcon {
    color: gray;
    height: 14px;
    margin-bottom: -4px;
    left: -4px;
    top: 5px;
    width: 25px;
    transform: rotate(90deg);
    position: absolute;
}

.duration {
    text-align: right;
    color: #444;
    padding-right: 10px;
    width: 1%;
}

.trackTitle {
    width: 100%;
    word-break: break-word;
    position: relative;
}

.credits {
    background-color: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin: 0;
    margin-left: 1rem;
    overflow-y: hidden;
    padding: 0;

    user-select: none;

    &.expanded {
        user-select: text;
    }

    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: height 300ms;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.measure {
    composes: credits;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

table {
    border-collapse: collapse;
    line-height: 18px;
    width: 100%;
    table-layout: auto;

    tr {
        vertical-align: top;
        border-top: 1px solid #e5e5e5;

        &:first-of-type {
            border-top: 0;
        }
    }

    td {
        padding: 2px;
    }
}

.spacer {
    width: 100%;
    flex-shrink: 100;
}

.toggle {
    flex-shrink: 0;
}

@media screen and (max-width: 600px) {
    td {
        padding: 7px 1px;
    }

    .subtrackPos {
        min-width: 0;
    }

    .trackTitle {
        min-width: 0;
    }
    .trackCredits {
        min-width: 95%;
    }
}

@media (prefers-reduced-motion: reduce) {
    blockquote {
        transition: none;
    }
}

.buttons {
    text-align: right;
    .info {
        font-weight: bold;
    }

    button {
        margin-left: 1em;
        svg {
            margin-top: 0.2em;
            margin-left: 0.5em;
        }
    }

    select {
        margin-right: 1em;
    }
}
